export class Empleados implements IEmpleados {
    id: number;
    primerNombre: string;
    segundoNombre: string;
    primerApellido: string;
    segundoApellido: string;
    tipoDocumento: string;
    numeroDocumento: string;
    fechaNacimiento: Date;
    idSexo: number;
    direccion: string;
    cargo: string;
    telefono: number;
    estado: boolean;
    email: string;
    nombreCompleto: string;
    idContratoVigente:number | null;
    fechaIngreso: Date;
    idTipoEmpleado:number | null;
    idEstadoCivil:number | null;
    nombre: string;
  }

  export interface IEmpleados {
    id: number;
    primerNombre: string;
    segundoNombre: string;
    primerApellido: string;
    segundoApellido: string;
    tipoDocumento: string;
    numeroDocumento: string;
    fechaNacimiento: Date;
    idSexo: number;
    direccion: string;
    telefono: number;
    cargo: string;
    estado: boolean;
    email: string;
    nombreCompleto: string;
    idContratoVigente:number | null;
    fechaIngreso: Date;
    idTipoEmpleado:number | null;
    idEstadoCivil:number | null;
  }



